import "./styles.css";
import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import Home from "./pages/Home";
import SponsorForm from "./pages/SponsorForm";
import TicketForm from "./pages/ticketForm";
import AboutUs from "./pages/AboutUs";

const Layout = () => {
  return (
    <div className="app">
      <Outlet />
    </div>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/sponsorform",
        element: <SponsorForm />,
      },
      {
        path: "/ticketform",
        element: <TicketForm />,
      },
      {
        path: "/about",
        element: <AboutUs />,
      },
    ],
  },
]);

const App = () => {
  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
};

export default App;
