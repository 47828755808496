import React from "react";
import "./speakers.css";

const Speakers = () => {
  return (
    <div className="speakers">
      <img src="./images/speakers.jpg" alt="Microphone" />
      <div className="overlay__speakers"></div>
      <div className="breadcrumb-title_speakers">
        <h4>Coming Soon</h4>
      </div>
      <hr className="line__speakers" />
      <div className="section-title_speakers">
        <h2>The brilliant minds shaping the world of music and arts</h2>
      </div>
      <div className="description_speakers">
        <h3>
          These Visionaries will share their insights, experiences, and
          innovate, ideas, providing a source of inspiration and knowledge for
          all attending.
        </h3>
      </div>
    </div>
  );
};

export default Speakers;
