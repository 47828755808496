import React from "react";
import "./navbar.css";

const Navbar = () => {
  return (
    <div>
      <header className="header">
        <div className="logo">
          <a href="/">
            <img className="logo-image" src="/logo.png" alt="" />
            {/* <span>AMC</span> */}
          </a>
        </div>
        <input type="checkbox" id="check" />
        <label for="check" className="icons">
          <i className="bx bx-menu" id="menu-icon"></i>
          <i className="bx bx-x" id="close-icon"></i>
        </label>
        <nav className="navbar">
          <a href="#about">ABOUT</a>
          <a href="#agenda">AGENDA</a>
          <a href="#speakers">SPEAKERS</a>
          <a href="#partners">SPONSORS</a>
          <a href="/ticketform">CONTACT</a>
        </nav>
      </header>
    </div>
  );
};

export default Navbar;
