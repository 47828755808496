import "./agenda.css";
// import CardAgenda from "./Card_agenda";

const Agenda = () => {
  return (
    <div className="container__agenda">
      {/* Title Section */}
      <div className="row_section-title__agenda flow">
        <div className="breadcrumb-title_agenda">
          <h4>What To Expect</h4>
        </div>
        <div className="section-title_agenda">
          <h2>Conference Highlights</h2>
        </div>
        <hr className="line__agenda" />
        <div className="description__agenda">
          <h3>
            Discover the Key Moments of Africa Music Conference. Engage, learn,
            and connect over four days of transformative experiences.
          </h3>
        </div>
        {/* Features Section */}
      </div>
      <div className="row_section_features__agenda">
        <div className="card_features__agenda">
          {/* Card 1 - Business Collaborations */}
          <img src="/images/collabo.png" alt="" />
          <hr className="line_card_features__agenda" />
          <h4 className="card_title">Business Collaborations</h4>
          <p className="card_text">
            Networking sessions and opportunities to connect with industry
            peers.
          </p>
        </div>
        {/* Card 2 - Speed Networking */}
        <div className="card_features__agenda">
          <img src="/images/networking.png" alt="" />
          <hr className="line_card_features__agenda" />
          <h4 className="card_title">Speed Networking</h4>
          <p className="card_text">
            Fast-paced meetings to help attendees form new partnerships.
          </p>
        </div>
        {/* Card 3 - Artist Showcase */}
        <div className="card_features__agenda">
          <img src="/images/showcase.png" alt="" />
          <hr className="line_card_features__agenda" />
          <h4 className="card_title">Artist Showcases</h4>
          <p className="card_text">
            Performances by rising stars and established artists.
          </p>
        </div>
        {/* Card 4 -  Panel Discussions */}
        <div className="card_features__agenda">
          <img src="/images/panel.png" alt="" />
          <hr className="line_card_features__agenda" />
          <h4 className="card_title">Panel Discussions</h4>
          <p className="card_text">Insights from industry thought leaders.</p>
        </div>
        {/* Card 5 - Exhibitions */}
        <div className="card_features__agenda">
          <img src="/images/exhibitions.png" alt="" />
          <hr className="line_card_features__agenda" />
          <h4 className="card_title">Exhibitions</h4>
          <p className="card_text">
            Businesses and institutions showcasing their offerings.
          </p>
        </div>
        {/* Card 6 - Entertainment */}
        <div className="card_features__agenda">
          <img src="/images/entertainment.png" alt="" />
          <hr className="line_card_features__agenda" />
          <h4 className="card_title">Entertainment</h4>
          <p className="card_text">
            Live music and cultural performances throughout the event.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Agenda;
