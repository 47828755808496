import "./aboutUs.css";
import Footer from "../components/footer/Footer";
import NavbarFull from "../components/navbarFull/Navbar";

const AboutUs = () => {
  return (
    <div>
      <article>
        <NavbarFull />
      </article>
      <main>
        <div className="container__aboutUs">
          <header className="row__title__aboutUs">
            <div className="breadcrumbTitle__aboutUs">
              <h4>About Us</h4>
            </div>
            <div className="title__aboutUS">
              <h2>Africa Music Conference</h2>
            </div>
          </header>
          <hr className="line__aboutUs" />
          <div className="text__aboutUS flow">
            <h3>Africa Music Conference Background</h3>
            <p>
              The Africa Music Conference (AMC) is a pioneering platform for
              creatives, music professionals, art exhibitors, and filmmakers to
              explore the intersection of arts and business. This gathering aims
              to foster innovation and growth within Zambia's creative industry
              and across Africa, helping to transform the landscape through
              collaboration and forward-thinking strategies.
            </p>
            <h4>Vision</h4>
            <p>
              To foster growth and create opportunities for musicians, as well
              as the art and entertainment industry throughout Africa.
            </p>
            <h4>Mission</h4>
            <p>
              To host music and creatives conferences across Africa, equipping
              music creators and industry stakeholders with the tools and
              strategies needed to understand the business of music and achieve
              success in their ventures.
            </p>
            <h3>About Africa Music Conference 2024</h3>
            <p>
              The inaugural event, set to take place in Lusaka, Zambia, will
              bring together key sectors such as arts, culture and recreation,
              technology and innovation, and tourism. With opportunities in
              business collaborations, speed networking, showcases,
              presentations, panel discussions, exhibitions, and entertainment,
              AMC will serve as a hub for creative professionals to exchange
              knowledge and showcase their work. By promoting African music on
              the global stage, AMC will bridge the gap between African artists
              and worldwide audiences through innovative technologies and
              networks.
            </p>
            <h3>
              Transforming Music in Africa: Blockchain Solutions for Fair and
              Transparent Growth
            </h3>
            <p>
              AMC is also the first music association in Africa spearheading
              blockchain adoption within the music industry. By integrating
              blockchain technology, AMC will provide transparency, fairness,
              and efficiency to the music ecosystem, ensuring artists,
              composers, and producers are fairly compensated for their work.
              Blockchain's decentralized infrastructure will revolutionize
              rights management, distribution, and revenue tracking, creating
              new opportunities for creatives across the continent. Through this
              forward-thinking approach, AMC aims to elevate African music on a
              global scale, empowering artists with the tools of the future.
            </p>
            <p>
              Artists, musicians, composers, publishers, producers, and
              arrangers all play a crucial role in bolstering Africa's economy
              and cultural identity. Recognizing their significance, AMC is
              dedicated to championing the creative sector as a key pillar of
              growth and societal transformation.
            </p>
          </div>
        </div>
      </main>
      <article>
        <Footer />
      </article>
    </div>
  );
};

export default AboutUs;
