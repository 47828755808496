import React from "react";
import "./footer.css";

const Footer = () => {
  return (
    <div className="container__footer">
      <div className="row__footer">
        <div className="navbar__footer">
          <div className="logo__footer">
            <img src="/logo.png" alt="Logo" />
          </div>
          <div className="links__footer">
            <a href="/about">About Us</a>
            <a href="/">Agenda</a>
            <a href="/">Speakers</a>
            <a href="/">Sponsors</a>
            <a href="/ticketform">Contact Us</a>
          </div>
        </div>
        <hr className="line__footer" />
        <div className="base__footer">
          <p>&copy; 2024 All rights reserved. Africa Music Conference</p>
          <div className="icons__footer">
            <a href="/">
              <img src="./images/facebook.png" alt="Facebook Logo" />
            </a>
            <a href="/">
              <img src="./images/linkedin.png" alt="LinkedIn Logo" />
            </a>
            <a href="/">
              <img src="./images/youtube.png" alt="YouTube Logo" />
            </a>
            <a href="/">
              <img src="./images/instagram.png" alt="Instagram Logo" />
            </a>
            <a href="/">
              <img src="./images/telegram.png" alt="Telegram Logo" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
