import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import Footer from "../components/footer/Footer";
import NavbarFull from "../components/navbarFull/Navbar";
import "./ticketForm.css";

const TicketForm = () => {
  const formRef = useRef();
  const [error, setError] = useState(false);
  const [Success, setSuccess] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_tvgct3m", "template_knrspfj", formRef.current, {
        publicKey: "T74Z5RqgVPbCax8P1",
      })
      .then(
        (result) => {
          setSuccess(true);
        },
        (error) => {
          setError(true);
        }
      );
  };
  return (
    <>
      <article>
        <NavbarFull />
      </article>
      <main>
        <div className="container__TicketForm">
          <div className="row_title__TicketForm">
            <div className="title__TicketForm">
              <h2>General Form</h2>
            </div>
            <hr className="line__TicketForm" />
          </div>

          <div className="form__TicketForm">
            <form ref={formRef} onSubmit={sendEmail}>
              <p>
                <label for="name">Full Name: </label>
              </p>
              <input
                className="field__ticketForm"
                type="text"
                id="name"
                required
                placeholder="e.g. John Doe"
                name="name"
              />
              <br />
              <p>
                <label for="email">Email: </label>
              </p>
              <input
                className="field__ticketForm"
                type="email"
                id="email"
                required
                placeholder="email@company.com"
                name="email"
              />
              <br />
              <label for="topic">Enter your topic concern here:</label>
              <br />
              <input
                className="field__ticketForm"
                type="text"
                id="topic"
                required
                placeholder="e.g. Tickets, Sponsorship, General"
                name="topic"
              />
              <br />
              <br />
              <textarea
                className="field__ticketForm"
                placeholder="write your message where applicable..."
                name="message"
                id="message"
                rows={8}
              ></textarea>
              <br />
              <br />
              <button className="button_register">Submit</button>
              <br />
              <br />
              {error && "Error"}
              {Success && "Success"}
            </form>
          </div>
        </div>
      </main>
      <article>
        <Footer />
      </article>
    </>
  );
};

export default TicketForm;
