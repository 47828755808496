import "./register.css";

export const Register = () => {
  return (
    <div className="speakers">
      <img src="./images/register.jpg" alt="Microphone" />
      <div className="overlay__speakers"></div>
      <div className="breadcrumb-title_speakers">
        <h4>get your ticket</h4>
      </div>
      <hr className="line__register" />
      <div className="section-title_speakers">
        <h2>Secure Your Spot at Africa's Leading Music Conference</h2>
      </div>
      <div className="cta_register">
        <a href="/ticketform" className="button_register">
          Get Started
        </a>
      </div>
    </div>
  );
};
