import "./packages.css";

const Packages = () => {
  return (
    <div className="container__packages">
      <div className="title__packages">
        <div className="breadcrumb-title__packages">
          <h4>Sponsorship Packages</h4>
        </div>
        <div className="heading__packages">
          <h2>Become A Sponsorship</h2>
        </div>
        <hr className="line__packages" />
        <div className="description_sponsorship">
          <h3>
            Elevate Your Brand by Partnering with Africa's Creative Conference.
            Explore Exclusive Sponsorship Benefits.
          </h3>
        </div>
      </div>
      <div className="sponsorship__packages">
        {/* Silver Package */}
        <div className="card__packages">
          {/* Card 1 - Business Collaborations */}

          <div className="heading__card">
            <h4 className="card_title__packages">SILVER</h4>
            <h3>$10,000</h3>
            <hr className="line_card__packages" />
          </div>
          <ul className="card_text__packages">
            <li>
              <strong>Media Coverage:</strong> Featured mentions on TV, radio,
              and during press interviews.
            </li>
            <li>
              {" "}
              <strong>Digital Visibility: </strong>Prominent placement on our
              website and online platforms.
            </li>
            <li>
              {" "}
              <strong>Press Release Mentions: </strong>Inclusion in official
              press release statements.{" "}
            </li>
            <li>
              {" "}
              <strong>Exhibition Stand: </strong>A dedicated space to showcase
              your brand.{" "}
            </li>
            <li>
              {" "}
              <strong>Speed Networking Session: </strong>Exclusive access to
              fast-track networking opportunities.{" "}
            </li>
            <li>
              {" "}
              <strong>VIP Dinner: </strong>Join an exclusive dinner with key
              figures from government and the private sector. <br />
            </li>
            <li>
              {" "}
              <strong>Social Media Ads:</strong>Targeted social media promotion
              to boost your brand's visibility.
            </li>
          </ul>
          <div className="button__packages">
            <a href="/sponsorform" className="button-one">
              GET STARTED
            </a>
          </div>
        </div>
        {/* Gold Package */}
        <div className="card__packages">
          {/* Card 1 - Business Collaborations */}

          <div className="heading__card">
            <h4 className="card_title__packages">GOLD</h4>
            <h3>$15,000</h3>
            <hr className="line_card__packages" />
          </div>
          <ul className="card_text__packages">
            <li>
              <strong>Media Exposure:</strong> Prominent mentions on TV, radio,
              and in press interviews.
            </li>
            <li>
              {" "}
              <strong>Enhanced Visibility: </strong>Featured placement on our
              website, online platforms, and at the event.
            </li>
            <li>
              {" "}
              <strong>Press Release Inclusion: </strong>Your brand highlighted
              in official press releases.{" "}
            </li>
            <li>
              {" "}
              <strong>Exhibition Stand: </strong>Showcase your brand with a
              dedicated space.{" "}
            </li>
            <li>
              {" "}
              <strong>Panel Discussion Participation: </strong>Join industry
              leaders in high-profile discussions.{" "}
            </li>
            <li>
              <strong>Exclusive Networking</strong>
              Participate in speed networking sessions for impactful
              connections.
            </li>
            <li>
              {" "}
              <strong>VIP Dinner: </strong>Enjoy an exclusive dinner with
              government officials and private sector leaders.
            </li>
            <li>
              {" "}
              <strong>Comprehensive Advertising:</strong>Your brand featured on
              billboards, posters, and across social media.
            </li>
          </ul>
          <div className="button__packages">
            <a href="/sponsorform" className="button-two">
              GET STARTED
            </a>
          </div>
        </div>
        {/* Silver Package */}
        <div className="card__packages  platinum">
          {/* Card 1 - Business Collaborations */}

          <div className="heading__card">
            <h4 className="card_title__packages">PLATINUM</h4>
            <h3>$25,000</h3>
            <hr className="line_card__packages" />
          </div>
          <ul className="card_text__packages">
            <li>
              <strong>Exclusive Media Coverage: </strong>Direct interviews on
              TV, radio, and in press features.
            </li>
            <li>
              <strong>Unmatched Visibility: </strong>Premier placement on our
              website, online platforms, and event site.
            </li>
            <li>
              <strong>Press Release Highlights: </strong>Your brand prominently
              featured in official press releases.
            </li>
            <li>
              <strong>Exhibition Stand: </strong>Prime space to showcase your
              brand. Mall & Regional Activations: Engage with audiences through
              dedicated activation slots.
            </li>
            <li>
              <strong>Panel Discussion Role: </strong>Participate in key
              discussions with industry leaders.
            </li>
            <li>
              <strong>Presentation Opportunity: </strong>Secure a presentation
              slot to showcase your expertise.
            </li>
            <li>
              <strong>Elite Networking: </strong>Connect with key players in
              speed networking sessions. Festival Exposure: Take center stage
              with a slot at the festival, reaching up to 4,000 attendees.
            </li>
            <li>
              <strong>VIP Dinner: </strong>Join a prestigious dinner with
              government and private sector leaders.
            </li>
            <li>
              <strong>Comprehensive Advertising: </strong>Your brand displayed
              on billboards, posters, and social media campaigns.
            </li>
          </ul>
          <div className="button__packages">
            <a href="/sponsorform" className="button-three">
              LET'S TALK
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Packages;
