import "./home.css";
import Navbar from "../components/navbar/Navbar";
import HeroTitle from "../components/hero/HeroTitle";
import AboutAMC from "../components/about/AboutAMC";
import Agenda from "../components/agenda/Agenda";
import Speakers from "../components/speakers/Speakers";
import Packages from "../components/packages-cta/Packages";
import Partners from "../components/partners-sponsors/Partners";
import { Register } from "../components/registration-cta/Register";
import NewsletterForm from "../components/newsletter-form/NewsletterForm";
import Footer from "../components/footer/Footer";
// import { HashLink } from "react-router-hash-link";

const Home = () => {
  return (
    <>
      {/* Navbar */}
      <article>
        <Navbar />
      </article>
      {/* Hero */}
      <article>
        <HeroTitle />
      </article>
      {/* About Section */}
      <article id="about">
        <AboutAMC />
        {/* TO-DO MAKE ABOUT PAGE */}
      </article>
      {/* Agenda Features / What We Do Section */}
      <article id="agenda">
        <Agenda />
      </article>
      {/* Featured Speakers & Artists Section */}
      <article id="speakers">
        <Speakers />
      </article>
      {/* Partners & Sponsors */}
      <article id="partners">
        <Partners />
      </article>
      {/* Sponsorship Packages Section */}
      <article id="become-a-sponsor">
        <Packages />
      </article>
      {/* Tickets & Registration Section */}
      <article id="cta_registration">
        <Register />
      </article>
      {/* Newsletter & Updates Section */}
      <article id="newsletter">
        <NewsletterForm />
      </article>
      {/* Footer Section */}
      <article id="footer">
        <Footer />
      </article>
    </>
  );
};

export default Home;
