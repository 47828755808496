import React from "react";
import "./about.css";

const AboutAMC = () => {
  return (
    <div className="container__about-amc">
      <picture>
        <source media="(max-width: 37.5rem)" srcset="/images/about_small.jpg" />
        <source media="(max-width: 75rem)" srcset="/images/about_medium.jpg" />
        <source srcset="/images/about_big.jpg" />
        <img src="/images/about_big.jpg" alt="" />
      </picture>
      <div className="text_row__about-amc">
        <div className="breadcrumb-title__about-amc">
          <h4>About AMC</h4>
        </div>
        <div className="section-title__about-amc">
          <h2>
            Empowering Africa's <br /> Creatives
          </h2>
        </div>
        <hr className="line_about-amc" />
        <div className="section-subtitle__about-amc">
          <h3>Uniting Talent, Blockchain Innovation, and Opportunity</h3>
        </div>

        <div className="description__about-amc">
          <p>
            Africa Music Conference (AMC) unites Africa's creatives, music
            professionals, and artists to foster innovation and growth in the
            industry. This inaugural event in Lusaka, Zambia, offers a platform
            for knowledge-sharing, business opportunities, and talent showcases.
          </p>
          <br />
          <p>
            AMC is also pioneering blockchain adoption within the African music
            industry, making it the first music association in Africa to
            integrate this technology. Blockchain technology ensures that
            creators benefit directly from their work, offering decentralized
            solutions for royalties, intellectual property protection, and
            distribution, making the music industry more sustainable and
            equitable for all stakeholders.
          </p>
        </div>
        <div className="cta_speakers">
          <a href="/about" className="button_register">
            Learn More
          </a>
        </div>
      </div>
    </div>
  );
};

export default AboutAMC;
