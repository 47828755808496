import "./partners.css";

const Partners = () => {
  return (
    <div className="container__partners">
      <div className="row_title_partners">
        <div className="breadcrumb-title__partners">
          <h4>Thank You</h4>
        </div>
        <div className="title__partners">
          <h2>Partners</h2>
        </div>
        <hr className="line__partners" />
        <div className="description__partners">
          <h3>
            {" "}
            Honoring Our Key Supporters Who Help Bring Africa’s Creative Vision
            to Life
          </h3>
        </div>
      </div>
      <div className="switcher__partners">
        <div className="partners_box">
          <img src="/images/uaba.png" alt="UABA" />
          <img src="/images/nac.jpg" alt="National Art Council" />
          <img src="/images/techcon.png" alt="TechCon" />
          <img src="/images/music-club.png" alt="Music Club" />
          <img src="/images/music-exchange.png" alt="Music Exchange" />
        </div>
      </div>
    </div>
  );
};

export default Partners;
