import "./newsletterForm.css";

const NewsletterForm = () => {
  return (
    <div className="container__newsletter">
      {/* Title - Newsletter */}
      <div className="text__newsletter">
        <div className="title__newsletter">
          <h2>stay informed</h2>
        </div>
        <hr className="line_newsletter" />
        <div className="description__newsletter">
          <h3>
            Sign up for event updates, exclusive content, and special offers.
            Get early access to tickets and exclusive behind-the-scenes content.
          </h3>
        </div>
      </div>

      {/* Form Newsletter */}
      <div className="form__newsletter">
        <div className="theForm" action="">
          <a href="/ticketform" className="button_subscribe">
            Subscribe Now
          </a>
        </div>
      </div>
    </div>
  );
};

export default NewsletterForm;
